<template>
<!-- eslint-disable -->
<svg width="117px" height="31px" viewBox="0 0 117 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Bionic logo</title>
    <g id="Partner-LP's----work-file" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="1.1" transform="translate(-1092.000000, -219.000000)" fill-rule="nonzero">
            <g id="LOGO/Navy" transform="translate(1092.000000, 219.000000)">
                <g id="R" transform="translate(109.208861, 8.317073)" fill="#152D72">
                    <path d="M3.6635273,4.5456134 L3.24872473,4.5456134 L3.24872473,5.79486713 L2.78783299,5.79486713 L2.78783299,2.41725519 L4.07832987,2.41725519 C4.81575666,2.41725519 5.09229171,2.97247907 5.09229171,3.48143429 C5.09229171,3.94412086 4.86184583,4.45307608 4.21659739,4.49934474 L5.09229171,5.74859847 L4.49313244,5.74859847 L3.6635273,4.5456134 Z M3.24872473,4.08292683 L3.89397317,4.08292683 C4.35486492,4.08292683 4.53922161,3.85158355 4.53922161,3.48143429 C4.53922161,3.11128504 4.30877574,2.87994175 3.98615152,2.87994175 L3.24872473,2.87994175 L3.24872473,4.08292683 Z" id="Shape"></path>
                    <path d="M3.847884,7.55307608 C1.95822785,7.55307608 0.437285094,6.02621041 0.437285094,4.12919549 C0.437285094,2.23218056 1.95822785,0.705314889 3.847884,0.705314889 C5.73754015,0.705314889 7.2584829,2.23218056 7.2584829,4.12919549 C7.21239373,6.02621041 5.69145097,7.55307608 3.847884,7.55307608 Z M3.847884,1.02919549 C2.14258455,1.02919549 0.759909314,2.41725519 0.759909314,4.12919549 C0.759909314,5.84113578 2.14258455,7.22919549 3.847884,7.22919549 C5.55318345,7.22919549 6.93585868,5.84113578 6.93585868,4.12919549 C6.93585868,2.41725519 5.55318345,1.02919549 3.847884,1.02919549 Z" id="Shape"></path>
                </g>
                <g id="Bionic">
                    <path d="M17.0990837,14.2507463 C20.2331476,13.0477612 21.2471094,10.5029851 21.2471094,8.51343284 C21.2471094,3.28507463 17.698243,0 12.2136312,0 L6.49857359,0 L6.49857359,5.73731343 L11.5222936,5.73731343 C13.4119497,5.73731343 14.3798224,7.03283582 14.3798224,8.92985075 C14.3798224,10.7343284 13.227593,12.0761194 11.5683828,12.0761194 L6.49857359,12.0761194 L6.49857359,5.73731343 L0,5.73731343 L0,30.6298507 L13.0893255,30.6298507 C19.1730965,30.6298507 22.8141413,26.3268657 22.8141413,21.2373134 C22.8602305,18.738806 21.1088419,15.2686567 17.0990837,14.2507463 Z M12.7667013,24.8925373 L6.49857359,24.8925373 L6.49857359,17.4895522 L12.6284338,17.4895522 C14.9328925,17.4895522 16.0390327,19.3865672 16.0390327,21.1447761 C16.0390327,22.9492537 14.9328925,24.8925373 12.7667013,24.8925373 Z" id="Shape" fill="#152D72"></path>
                    <rect id="Rectangle" fill="#152D72" x="25.2107784" y="9.71641791" width="6.03768184" height="20.9134328"></rect>
                    <rect id="Rectangle" fill="#152D72" x="80.0108067" y="9.71641791" width="6.03768184" height="20.9134328"></rect>
                    <rect id="Rectangle" fill="#FF6841" x="25.2107784" y="0" width="5.85332515" height="5.8761194"></rect>
                    <path d="M103.608464,22.3940299 C102.73277,23.8283582 101.165738,24.8 99.36826,24.8 C96.6489987,24.8 94.4367183,22.5791045 94.4367183,19.8492537 C94.4367183,17.119403 96.6489987,14.8985075 99.36826,14.8985075 C101.119649,14.8985075 102.686681,15.8238806 103.562375,17.2119403 L108.171292,13.6955224 C106.235547,10.919403 103.009305,9.06865672 99.36826,9.06865672 C93.4688456,9.06865672 88.6294823,13.880597 88.6294823,19.8492537 C88.6294823,25.8179104 93.4227565,30.6298507 99.36826,30.6298507 C103.055394,30.6298507 106.281636,28.7791045 108.217381,25.9567164 L103.608464,22.3940299 Z" id="Path" fill="#152D72"></path>
                    <path d="M44.1995182,9.06865672 C38.3001039,9.06865672 33.4607406,13.880597 33.4607406,19.8492537 C33.4607406,25.8179104 38.2540147,30.6298507 44.1995182,30.6298507 C50.1450217,30.6298507 54.9382959,25.8179104 54.9382959,19.8492537 C54.9382959,13.880597 50.1450217,9.06865672 44.1995182,9.06865672 Z M44.1995182,24.7537313 C41.4802569,24.7537313 39.3140657,22.5791045 39.3140657,19.8492537 C39.3140657,17.119403 41.4802569,14.9447761 44.1995182,14.9447761 C46.9187795,14.9447761 49.0849707,17.119403 49.0849707,19.8492537 C49.0849707,22.5791045 46.9187795,24.7537313 44.1995182,24.7537313 Z" id="Shape" fill="#152D72"></path>
                    <polygon id="Path" fill="#152D72" points="57.3349329 9.71641791 57.3349329 30.6761194 63.3726148 30.6761194 63.3726148 15.7776119 70.5164368 15.7776119 70.5164368 30.6761194 76.5541186 30.6761194 76.5541186 9.71641791"></polygon>
                    <path d="M82.9144247,7.2641791 L82.9144247,7.2641791 C84.8040809,7.2641791 86.3711128,5.73731343 86.3711128,3.79402985 L86.3711128,3.79402985 C86.3711128,1.89701493 84.85017,0.323880597 82.9144247,0.323880597 L82.9144247,0.323880597 C81.0247686,0.323880597 79.4577366,1.85074627 79.4577366,3.79402985 L79.4577366,3.79402985 C79.4577366,5.69104478 80.9786794,7.2641791 82.9144247,7.2641791 Z" id="Path" fill="#FF6841"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>

<script>
export default {
  name: 'SvgBionic'
}
</script>
