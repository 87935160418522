<template>
  <svg
    width="183px"
    height="73px"
    viewBox="0 0 183 73"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 16</title>
    <g
      id="Partner-LP's----work-file"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="1.1" transform="translate(-162.000000, -132.000000)" fill-rule="nonzero">
        <g id="Group-16" transform="translate(162.000000, 132.000000)">
          <g id="Group-10" transform="translate(0.000000, 58.604651)">
            <path id="Shape" d="M5.58270862,11.0098758 C6.68698065,11.0098758 7.72990424,10.6428799 8.65013094,10.0923861 L11.7789017,13.2118509 C12.0242955,13.4565148 12.4537346,13.4565148 12.6991284,13.2118509 L13.3126129,12.6001911 C13.5580066,12.3555272 13.5580066,11.9273654 13.3126129,11.6827015 L10.1224936,8.5632367 C10.6746297,7.70691303 11.0427203,6.66709143 11.0427203,5.50493788 C11.0427203,2.50780503 8.58878249,5.18703193e-15 5.52136017,5.18703193e-15 C2.5152863,5.18703193e-15 0,2.44663906 0,5.50493788 C0.0613484464,8.5632367 2.5152863,11.0098758 5.58270862,11.0098758 Z M5.58270862,2.20197515 C7.42316201,2.20197515 8.89552472,3.66995859 8.89552472,5.50493788 C8.89552472,7.33991717 7.42316201,8.80790061 5.58270862,8.80790061 C3.74225523,8.80790061 2.26989252,7.33991717 2.26989252,5.50493788 C2.20854407,3.66995859 3.74225523,2.20197515 5.58270862,2.20197515 Z" fill="#FF5B2E" />
            <g id="Group" transform="translate(20.981169, 0.856324)" fill="#3E5389">
              <path id="Shape" d="M8.58878249,8.93023256 C8.58878249,10.7040459 7.23911667,12.3555272 5.0305726,12.3555272 L0,12.3555272 L0,0.122331953 L4.60113348,0.122331953 C6.62563221,0.122331953 7.91394958,1.34565148 7.91394958,3.24179675 C7.91394958,4.28161835 7.5458589,5.38260593 6.38023842,5.81076776 C7.79125269,6.17776362 8.58878249,7.58458108 8.58878249,8.93023256 Z M1.65640805,1.65148136 L1.65640805,5.26027397 L4.66248192,5.26027397 C5.76675396,5.26027397 6.31888997,4.52628226 6.31888997,3.42529468 C6.31888997,2.38547308 5.70540551,1.65148136 4.60113348,1.65148136 L1.65640805,1.65148136 Z M6.99372288,8.80790061 C6.99372288,7.70691303 6.25754153,6.66709143 4.78517882,6.66709143 L1.65640805,6.66709143 L1.65640805,10.8875438 L4.72383037,10.8875438 C6.19619308,10.8875438 6.99372288,9.90888818 6.99372288,8.80790061 Z" />
              <path id="Path" d="M12.8218253,8.19624084 L12.8218253,0.183497929 L14.4168849,0.183497929 L14.4168849,8.19624084 C14.4168849,10.0923861 15.7052023,11.0098758 17.2389134,11.0098758 C18.7726246,11.0098758 20.060942,10.0923861 20.060942,8.19624084 L20.060942,0.183497929 L21.6560016,0.183497929 L21.6560016,8.19624084 C21.6560016,10.9487098 19.6315028,12.5390252 17.177565,12.5390252 C14.9076725,12.5390252 12.8218253,10.9487098 12.8218253,8.19624084 Z" />
              <path id="Path" d="M26.0117413,8.86906658 L27.6068009,8.86906658 C27.6681493,9.97005416 28.7110729,11.0710417 30.3061325,11.0710417 C31.9011921,11.0710417 32.7600704,10.0923861 32.7600704,8.99139853 C32.7600704,7.82924498 31.6557983,7.27875119 30.6128747,7.03408729 L29.0178151,6.66709143 C26.4411804,6.05543167 26.0117413,4.52628226 26.0117413,3.54762663 C26.0117413,1.40681746 27.9135431,0.0611659764 30.0607387,0.0611659764 C32.2079343,0.0611659764 33.9256908,1.40681746 33.9256908,3.60879261 L32.3306312,3.60879261 C32.3306312,2.38547308 31.2877076,1.52914941 29.9993903,1.52914941 C28.7724213,1.52914941 27.6068009,2.26314113 27.6068009,3.48646066 C27.6068009,3.91462249 27.7294978,4.77094616 29.3859058,5.199108 L30.9809654,5.56610385 C33.0668126,6.05543167 34.2937815,7.21758522 34.2937815,8.93023256 C34.2937815,10.8263778 32.7600704,12.5390252 30.1834356,12.5390252 C27.7908462,12.5390252 26.0730897,10.7652119 26.0117413,8.86906658 Z" />
              <polygon id="Path" points="38.7722181 0.122331953 40.3672777 0.122331953 40.3672777 12.3555272 38.7722181 12.3555272" />
              <polygon id="Path" points="54.4160719 0.122331953 54.4160719 12.3555272 52.8210123 12.3555272 47.1769552 3.05829882 47.1769552 12.3555272 45.5818956 12.3555272 45.5818956 0.122331953 47.1769552 0.122331953 52.8210123 9.41956037 52.8210123 0.122331953" />
              <polygon id="Path" points="66.9925034 10.8875438 66.9925034 12.3555272 59.5693414 12.3555272 59.5693414 0.122331953 66.8084581 0.122331953 66.8084581 1.59031539 61.164401 1.59031539 61.164401 5.56610385 66.0722767 5.56610385 66.0722767 7.09525327 61.164401 7.09525327 61.164401 10.8263778 66.9925034 10.8263778" />
              <path id="Path" d="M70.5507133,8.86906658 L72.1457729,8.86906658 C72.2071214,9.97005416 73.2500449,11.0710417 74.8451046,11.0710417 C76.4401642,11.0710417 77.2990424,10.0923861 77.2990424,8.99139853 C77.2990424,7.82924498 76.1947704,7.27875119 75.1518468,7.03408729 L73.5567872,6.66709143 C70.9801524,6.05543167 70.5507133,4.52628226 70.5507133,3.54762663 C70.5507133,1.40681746 72.4525151,0.0611659764 74.5997108,0.0611659764 C76.7469064,0.0611659764 78.4646629,1.40681746 78.4646629,3.60879261 L76.8696033,3.60879261 C76.8696033,2.38547308 75.8266797,1.52914941 74.5383623,1.52914941 C73.3113934,1.52914941 72.1457729,2.26314113 72.1457729,3.48646066 C72.1457729,3.91462249 72.2684698,4.77094616 73.9248779,5.199108 L75.5199375,5.56610385 C77.6057846,6.05543167 78.8327536,7.21758522 78.8327536,8.93023256 C78.8327536,10.8263778 77.2990424,12.5390252 74.7224077,12.5390252 C72.3298183,12.5390252 70.6120618,10.7652119 70.5507133,8.86906658 Z" />
              <path id="Path" d="M82.5136603,8.86906658 L84.10872,8.86906658 C84.1700684,9.97005416 85.212992,11.0710417 86.8080516,11.0710417 C88.4031112,11.0710417 89.2619894,10.0923861 89.2619894,8.99139853 C89.2619894,7.82924498 88.1577174,7.27875119 87.1147938,7.03408729 L85.5197342,6.66709143 C82.9430995,6.05543167 82.5136603,4.52628226 82.5136603,3.54762663 C82.5136603,1.40681746 84.4154622,0.0611659764 86.5626578,0.0611659764 C88.7098534,0.0611659764 90.4276099,1.40681746 90.4276099,3.60879261 L88.8325503,3.60879261 C88.8325503,2.38547308 87.7896267,1.52914941 86.5013094,1.52914941 C85.2743404,1.52914941 84.10872,2.26314113 84.10872,3.48646066 C84.10872,3.91462249 84.2314168,4.77094616 85.8878249,5.199108 L87.4828845,5.56610385 C89.5687317,6.05543167 90.7957006,7.21758522 90.7957006,8.93023256 C90.7957006,10.8263778 89.2619894,12.5390252 86.6853547,12.5390252 C84.2314168,12.5390252 82.5136603,10.7652119 82.5136603,8.86906658 Z" />
              <polygon id="Path" points="101.47033 0.122331953 103.06539 0.122331953 103.06539 10.8263778 108.095962 10.8263778 108.095962 12.2943613 101.47033 12.2943613" />
              <path id="Shape" d="M110.427203,6.30009557 C110.427203,2.81363492 113.126535,0 116.623397,0 C120.120258,0 122.880938,2.81363492 122.880938,6.30009557 C122.880938,9.78655623 120.181606,12.5390252 116.623397,12.5390252 C113.126535,12.5390252 110.427203,9.72539025 110.427203,6.30009557 Z M121.22453,6.30009557 C121.22453,3.66995859 119.26138,1.52914941 116.623397,1.52914941 C114.046762,1.52914941 112.022263,3.66995859 112.022263,6.30009557 C112.022263,8.93023256 113.985413,11.0710417 116.623397,11.0710417 C119.26138,11.0710417 121.22453,8.86906658 121.22453,6.30009557 Z" />
              <path id="Shape" d="M133.555568,9.23606244 L128.279601,9.23606244 L127.113981,12.3555272 L125.396224,12.3555272 L130.058706,0.122331953 L131.776463,0.122331953 L136.438945,12.3555272 L134.721188,12.3555272 L133.555568,9.23606244 Z M133.003432,7.82924498 L130.917585,2.3243071 L128.831737,7.82924498 L133.003432,7.82924498 Z" />
              <polygon id="Path" points="148.769982 0.122331953 148.769982 12.3555272 147.174923 12.3555272 141.530866 3.05829882 141.530866 12.3555272 139.935806 12.3555272 139.935806 0.122331953 141.530866 0.122331953 147.174923 9.41956037 147.174923 0.122331953" />
              <path id="Path" d="M153.187071,8.86906658 L154.78213,8.86906658 C154.843479,9.97005416 155.886402,11.0710417 157.481462,11.0710417 C159.076521,11.0710417 159.9354,10.0923861 159.9354,8.99139853 C159.9354,7.82924498 158.831128,7.27875119 157.788204,7.03408729 L156.193144,6.66709143 C153.61651,6.05543167 153.187071,4.52628226 153.187071,3.54762663 C153.187071,1.40681746 155.088872,0.0611659764 157.236068,0.0611659764 C159.383264,0.0611659764 161.10102,1.40681746 161.10102,3.60879261 L159.567309,3.60879261 C159.567309,2.38547308 158.524385,1.52914941 157.236068,1.52914941 C156.009099,1.52914941 154.843479,2.26314113 154.843479,3.48646066 C154.843479,3.91462249 154.966175,4.77094616 156.622584,5.199108 L158.217643,5.56610385 C160.30349,6.05543167 161.530459,7.21758522 161.530459,8.93023256 C161.530459,10.8263778 159.996748,12.5390252 157.420113,12.5390252 C154.904827,12.5390252 153.187071,10.7652119 153.187071,8.86906658 Z" />
            </g>
          </g>
          <g id="Group">
            <polygon id="Path" fill="#112B77" points="94.0304514 16.5581395 94.0304514 53.4573643 104.437468 53.4573643 104.437468 27.1007752 116.803451 27.1007752 116.803451 53.4573643 127.14925 53.4573643 127.14925 16.5581395" />
            <rect
              id="Rectangle"
              fill="#112B77"
              x="78.3587095"
              y="16.9922481"
              width="10.3457984"
              height="36.4031008"
            />
            <polygon id="Path" fill="#112B77" points="0 0.0620155039 0 10.6046512 11.3865 10.6046512 11.3865 53.4573643 21.7322983 53.4573643 21.7322983 10.6046512 33.1187983 10.6046512 33.1187983 0.0620155039" />
            <g transform="translate(37.220387, 0.000000)">
              <path id="Path" d="M137.678701,53.4573643 L137.678701,53.4573643 C140.923242,53.4573643 143.616822,50.7906977 143.616822,47.4418605 L143.616822,47.4418605 C143.616822,44.1550388 140.984459,41.4263566 137.678701,41.4263566 C134.434161,41.4263566 131.74058,44.0930233 131.74058,47.4418605 L131.74058,47.4418605 C131.74058,50.7286822 134.372943,53.4573643 137.678701,53.4573643" fill="#FF5B2E" />
              <path id="Path" d="M35.812379,31.4418605 L35.812379,53.5193798 L25.4665806,53.5193798 L25.7114516,34.8527132 C25.7114516,32.4341085 25.0380564,30.5116279 23.7524838,29.2093023 C22.4669112,27.8449612 20.6915967,27.2248062 18.5489758,27.2248062 C15.9778306,27.2248062 13.8352096,28.2790698 12.1823306,30.3255814 C12.1823306,30.3255814 12.1823306,30.3255814 12.1823306,30.3875969 C10.9579758,31.9379845 10.3457984,34.6666667 10.3457984,36.6511628 L10.3457984,53.5813953 L0,53.5813953 L0,0 L10.3457984,0 L10.3457984,21.2093023 C11.4477177,19.7829457 12.8557258,18.6046512 14.4473871,17.7984496 C16.5287903,16.6821705 18.7938467,16.124031 21.1201209,16.1860465 C25.6502338,16.1860465 29.1396451,17.5503876 31.5883548,20.3410853 C34.0370644,23.0077519 35.812379,26.5426357 35.812379,31.4418605" fill="#112B77" />
              <rect
                id="Rectangle"
                fill="#FF5B2E"
                x="41.260758"
                y="0.248062016"
                width="10.0397097"
                height="10.1705426"
              />
              <polygon id="Path" fill="#112B77" points="115.21179 53.4573643 104.988427 39.751938 104.988427 53.4573643 94.4589756 53.4573643 94.4589756 0.0620155039 104.988427 0.0620155039 104.988427 29.2713178 114.844484 17.0542636 128.067516 17.0542636 112.824298 34.6666667 128.067516 53.4573643" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgThinkNew'
}
</script>
